<template>
  <Layout>
    <div class="adscontainer">
      <div class="logo hd" />
      <h1 class="title">
        {{ content.noads_title }}
      </h1>
      <div class="na_text">
        <span v-html="content.ads_text" />
        <ExternalLink
          :url="o7policyUrl"
          :text="content.ads_text2"
        />
        <span v-html="content.ads_text3" />
      </div>
      <Footer
        module="noads"
        screen="noads"
      />
    </div>
  </Layout>
</template>

<script>
import Footer from '@components/Footer.vue'
import ExternalLink from '@components/ExternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Footer,
    ExternalLink,
  },
  data() {
    return {
      content: {},
      orientation: '',
      o7policyUrl: '',
    }
  },
  created() {
    this.$compliance.initCollector('consent_ads')
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation

    // request Outfit7 policy url
    this.$compliance.privacyUrl('outfit7').then((pp) => {
      this.o7policyUrl = pp
    })
  },
}
</script>

<style lang="scss">
.adscontainer {
  background: #f4e4a6;
  width: 100%;
  height: 100%;

  .logo {
    width: 52px;
    height: 34px;
    max-width: 130px;
    margin: 10px auto 0 auto;
    &.hd {
      width: 88px;
      background-image: url('../../assets/images/o7neo-logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .header {
    display: block;
    width: calc(100% - 100px);
    max-width: 460px;
    height: auto;
    margin: 0 auto;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    font-size: 1.8em;
    line-height: 1em;
    color: #f8f9fa;
    text-shadow: 1px 2px black;
    -webkit-text-stroke: 1px #000;
    text-align: center;
    text-transform: uppercase;
    @media screen and (min-width: 460px) {
      font-size: 2em;
    }
    @media screen and (min-width: 768px) {
      font-size: 3em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .na_text {
    padding: 0 0 10px 0;
    font-size: 0.8em;
    color: #54361f;
    text-align: center;
    margin: 0 0 70px 0;
    .external_link {
      .span {
        color: #54361f;
        text-decoration: underline;
        text-transform: none;
      }
    }
    @media screen and (min-width: 375px) {
      font-size: 0.9em;
    }
    @media screen and (min-width: 460px) {
      font-size: 1em;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.1em;
      margin: 0 0 80px 0;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.4em;
      padding: 0 30px 20px 30px;
    }
  }
  .footer {
    background: #f4e4a6;
    box-shadow: none;
    .global_consent_options .btn {
      color: #f8f9fa;
      text-shadow: 1px 2px black;
      -webkit-text-stroke: 1px #000;
      padding-bottom: 5px;
      &.green {
        background-image: url('../../assets/images/sa2_button-green.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.grey {
        background-image: url('../../assets/images/sa2_button-blue.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      & span {
        margin-top: 0;
      }
    }
    .pp_text {
      background: #f4e4a6;
      color: #54361f;
      @media screen and (min-width: 768px) {
        font-size: 1.1em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.4em;
      }
    }
  }
  .external_link .span {
    color: #54361f;
  }
}
</style>
